.form-container {
  width: 400px;
  background: #212121;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

/* Animated gradient border */
.form-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  padding: 1px;
  background: linear-gradient(
    var(--angle),
    #e81cff,
    #40c9ff
  );
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: rotate 3s linear infinite;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

/* For browsers that don't support @property, fallback animation */
@supports not (background: linear-gradient(var(--angle), #e81cff, #40c9ff)) {
  .form-container::before {
    animation: rotateSimple 3s linear infinite;
  }
  
  @keyframes rotateSimple {
    to {
      transform: rotate(360deg);
    }
  }
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 2;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-container .form-group label {
  color: #717171;
  font-size: 14px;
}

.form-container .form-group input,
.form-container .form-group textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #333;
  font-family: inherit;
  transition: border-color 0.3s ease;
}

.form-container .form-group textarea {
  height: 120px;
  resize: none;
}

.form-container .form-group input:focus,
.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  color: #717171;
  width: 100px;
  background: #313131;
  border: none;
  padding: 12px 16px;
  font-size: inherit;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-container .form-submit-btn:hover {
  background: #3a3a3a;
}